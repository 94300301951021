import React from 'react';
import { Dropdown, DropdownTheme } from 'wix-ui-tpa';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { getAllCategories } from '../../../common/selectors/categories-selectors';
import { connect } from '../../../common/components/runtime-context';
import { getRoute } from '../../../common/router/router-selectors';
import { ROUTE_CREATE_POST } from '@wix/communities-forum-client-commons/dist/src/constants/routes';
import {
  DISCUSSION,
  QUESTION,
} from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import withPermissions from '../../hoc/with-permissions';
import { getOptionsFromCategories } from './get-options-from-categories';
import { classes } from './category-dropdown-select-desktop.st.css';

const CategoryDropdownSelectDesktop = ({
  t,
  categories,
  isDiscussionRoute,
  can,
  onChange,
  initiallySelectedId,
  error,
}) => {
  const postType = isDiscussionRoute ? DISCUSSION : QUESTION;
  const options = getOptionsFromCategories({ t, categories, can, postType });

  return (
    <Dropdown
      data-hook="category-dropdown-select"
      error={error}
      errorMessage={t('category-dropdown-select.error')}
      placeholder={t('category-dropdown-select.placeholder')}
      initialSelectedId={initiallySelectedId}
      options={options}
      onChange={option => {
        onChange(categories.find(c => c._id === option.id));
      }}
      theme={DropdownTheme.Line}
      className={classes.dropdown}
    />
  );
};

CategoryDropdownSelectDesktop.propTypes = {
  t: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  isDiscussionRoute: PropTypes.bool,
  can: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  initiallySelectedId: PropTypes.number,
  error: PropTypes.bool,
};

const mapRuntimeToProps = state => ({
  categories: getAllCategories(state),
  isDiscussionRoute: getRoute(state).endsWith(ROUTE_CREATE_POST),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withTranslate,
  withPermissions,
)(CategoryDropdownSelectDesktop);
