import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import InputText from '../input-text';
import RichContentEditor from '../rich-content-editor-async';
import RichContentEditorFooter from '../rich-content-editor-footer';
import postFormSettings from '../../services/post-form-settings';
import withTranslate from '../../../common/components/with-translate/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import { withFastForm } from '../../../common/components/fast-form';
import { connect } from '../../../common/components/runtime-context';
import { SolvedIcon } from '../icons/solved-icon';
import getPostTheme from '../rich-content-editor/theme-post-form';
import styles from './post-form-mobile.scss';
import { DISCUSSION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import CategoryDropdownSelect from '../category-dropdown-select/category-dropdown-select';
import { areGuidelinesAvailable } from '../../selectors/guidelines-selectors';
import { TextButton } from 'wix-ui-tpa';
import { classes } from './post-form-mobile.st.css';
import withPermissions from '../../hoc/with-permissions';
import { getCategory } from '../../../common/selectors/categories-selectors';

export class PostFormMobile extends Component {
  onContentChange = value => this.props.fastForm.changeValue('content')(value);

  render() {
    const {
      postId,
      contentFontClassName,
      titleFontClassName,
      fastForm: { values, changeValue, isValid },
      t,
      areGuidelinesAvailableFn,
      onShowGuidelines,
      onCategoryChange,
      can,
      category,
    } = this.props;
    const titleValue = values.title || '';
    const onTitleChange = value => changeValue('title')(value);
    const containerClassName = classNames(
      styles.container,
      'forum-text-color',
      contentFontClassName,
    );
    const areGuidelinesForCategoryAvailable = areGuidelinesAvailableFn(values.categoryId);

    return (
      <div
        className={classNames(
          containerClassName,
          'forum-card-background-color',
          'forum-card-border-color',
        )}
      >
        <div className={styles.content}>
          <div className={styles.categorySelectWrapper}>
            {!postId && (
              <div className={styles.categorySelect}>
                <CategoryDropdownSelect
                  initiallySelectedId={values.categoryId}
                  onChange={category => {
                    changeValue('categoryId')(category._id, () => {
                      if (category.postTypes.length > 1) {
                        // @TODO remove fallback to discussion for mixed, when migration to commentType is done
                        changeValue('postType')(DISCUSSION, () => {
                          onCategoryChange();
                        });
                      } else {
                        changeValue('postType')(category.postTypes[0], () => {
                          onCategoryChange();
                        });
                      }
                    });
                  }}
                />
              </div>
            )}
            {areGuidelinesForCategoryAvailable && (
              <TextButton
                data-hook="toggle-guidelines"
                className={classes.button}
                priority="primary"
                onClick={() => onShowGuidelines(values.categoryId)}
              >
                {t('post-form.show-guidelines')}
              </TextButton>
            )}
          </div>
          <div className={styles.titleInputWrapper}>
            <InputText
              value={titleValue}
              onChange={onTitleChange}
              containerClassName={classNames(titleFontClassName, styles.titleInputContainer)}
              className={classNames(styles.titleInput, 'forum-text-color')}
              maxLength={140}
              aria-label={t('post-form.title')}
              placeholder={t('post-form.give-this-post-a-title')}
              autoFocus={!postId}
              data-hook="post-form__title-input"
              inputRef={r => (this.titleInputRef = r)}
            />
          </div>
          <div className={classNames(styles.editor, 'post-form__text-editor')}>
            <RichContentEditor
              initialState={values.content || undefined}
              placeholder={t('text-editor.placeholder')}
              onChange={this.onContentChange}
              focus={false}
              origin="post"
              themeGetter={getPostTheme}
              setRef={ref => (this.editor = ref)}
              contentId={postId}
              showAdvancedLinkSettings={can('edit', 'category', category)}
            />
          </div>
        </div>
        <RichContentEditorFooter submitIcon={postId && SolvedIcon} isDisabled={!isValid} />
      </div>
    );
  }
}

PostFormMobile.propTypes = {
  onSubmitButtonClick: PropTypes.func.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  t: PropTypes.func,
  postId: PropTypes.string,
  showMessage: PropTypes.func,
  fastForm: PropTypes.object,
  areGuidelinesAvailableFn: PropTypes.func.isRequired,
  onShowGuidelines: PropTypes.func.isRequired,
  category: PropTypes.object,
  onCategoryChange: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  showMessage: actions.showMessage,
  areGuidelinesAvailableFn: areGuidelinesAvailable(state),
  onShowGuidelines: categoryId => actions.showGuidelines(categoryId),
  category: getCategory(state, ownProps.categoryId),
});

export default flowRight(
  withFastForm(postFormSettings),
  connect(mapRuntimeToProps),
  withTranslate,
  withFontClassName,
  withPermissions,
)(PostFormMobile);
