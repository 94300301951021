import { flowRight, get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  EXPERIMENT_WIX_COMMENTS,
  EXPERIMENT_FORUM_TABS,
} from '@wix/communities-forum-client-commons/dist/src/constants/experiments';

import { connect } from '../../../common/components/runtime-context';
import { scrollToPostCommentForm } from '../../services/scroll-to-post-comment-form';
import { getCategories, getAllCategories } from '../../../common/selectors/categories-selectors';
import { getPagerStateItems } from '../../selectors/comments-pagination-selectors';
import Button from '../button';
import { SUBSCRIBE_SUCCESS } from '../../actions/subscribe';
import { UNSUBSCRIBE_SUCCESS } from '../../actions/unsubscribe';
import { FOLLOW_POST } from '../../constants/interactions';
import { SIDEBAR } from '../../constants/bi-locations';
import ensureAuth from '../../hoc/ensure-auth';
import withFontClassName from '../../hoc/with-font-class-name';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { getRelatedAndNewPosts } from '../../selectors/post-selectors';
import PostPageSidebarStats from './post-page-sidebar-stats';
import PostPageSidebarCategoryList from './post-page-sidebar-category-list';
import PostPageSidebarNewPosts from './post-page-sidebar-new-posts';
import styles from './post-page-sidebar.scss';
import StickyContainer from '../../containers/sticky-container/sticky-container';
import { LockSlimIcon } from '../icons/lock-slim-icon';
import { NotificationFollowingIcon } from '../icons/notification-following-icon';
import { NotificationIcon } from '../icons/notification-icon';
import {
  getIsViewsCountEnabled,
  getIsSimilarPostsEnabled,
} from '../../selectors/app-settings-selectors';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import SidebarPaginationSelect, {
  mapPaginationToOptions,
} from './post-page-sidebar-pagination-select';
import {
  getActivePage,
  parsePagerStateItemsUrl,
} from './post-page-sidebar-pagination-select.services';
import { getRouteParams } from '../../../common/router/router-selectors';
import withExperiment from '../../hoc/with-experiment';
import { getIsPostNew } from '../../containers/post-activity/post-activity.selectors';

const ActionButtonRaw = React.memo(({ className, text, isProtected, ...props }) => {
  const Component = isProtected ? ensureAuth(Button) : Button;
  return (
    <Component className={classNames(styles.button, className)} {...props} isSmall>
      {text}
    </Component>
  );
});

ActionButtonRaw.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  contentFontClassName: PropTypes.string,
  isSecondary: PropTypes.bool,
  isProtected: PropTypes.bool,
};

const ActionButton = withFontClassName(ActionButtonRaw);

class PostPageSidebar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activePage: null,
      activePages: [],
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.handleSetActivePage();
  }

  componentDidUpdate() {
    this.handleSetActivePage();
  }

  handleSetActivePage = () => {
    const { activePages } = this.state;
    const { paginationState } = this.props;

    const newActivePages = paginationState.filter(({ isActive }) => isActive);
    const [hasPageStateDiff, newActivePage] = getActivePage(activePages, newActivePages);

    if (hasPageStateDiff) {
      this.setState({ activePage: newActivePage, activePages: newActivePages });
    }
  };

  handleSubscription = () => {
    const {
      post: { _id, isSubscribed },
      subscribe,
      unsubscribe,
    } = this.props;

    if (isSubscribed) {
      unsubscribe(_id);
    } else {
      subscribe(_id);
    }
  };

  render() {
    const {
      post,
      categories,
      allCategories,
      posts,
      paginationState,
      isViewsCountEnabled,
      isSimilarPostsEnabled,
      isForumTabsEnabled,
      isWixCommentsEnabled,
      navigateWithinForum,
      t,
      getIsPostNew,
    } = this.props;
    const { activePage } = this.state;

    const hasMultipleCategories = allCategories.length > 1;
    const isCommentsDisabled = get(post, 'isCommentsDisabled', false);
    const followTextKey = post.isSubscribed ? 'post-actions.unfollow' : 'post-actions.follow';
    const commentTextKey = isCommentsDisabled ? 'post-page.commenting-off' : 'comment.comment';
    const paginationOptions = mapPaginationToOptions(paginationState, t);
    const showPageSelect = paginationOptions.length > 1;

    const commentButtonContent = (
      <span className={classNames(styles.commentButtonContent)}>
        {isCommentsDisabled && (
          <LockSlimIcon
            className={classNames('button-primary-icon-fill', styles.commentButtonIcon)}
          />
        )}
        {t(commentTextKey)}
      </span>
    );

    return (
      <StickyContainer defaultOffset={20}>
        <div className={classNames(styles.container)} ref={this.containerRef}>
          <section>
            <div className={styles.buttonContainer}>
              <ActionButton
                onClick={scrollToPostCommentForm}
                text={commentButtonContent}
                disabled={isCommentsDisabled}
                isProtected={false}
              />
            </div>

            <div className={styles.buttonContainer}>
              <ActionButton
                onClick={this.handleSubscription}
                text={
                  <div className={styles.followButtonContent}>
                    {post.isSubscribed ? (
                      <NotificationFollowingIcon className="button-fill" />
                    ) : (
                      <NotificationIcon className="button-fill" />
                    )}
                    <span>{t(followTextKey)}</span>
                  </div>
                }
                actionDetails={{ action: FOLLOW_POST, args: [post._id] }}
                isSecondary={true}
                isProtected
              />
            </div>

            {isWixCommentsEnabled && showPageSelect && (
              <div className={styles.buttonContainer}>
                <SidebarPaginationSelect
                  activePage={activePage}
                  options={paginationOptions}
                  handleOnChange={option => {
                    const pageToGo = paginationState.find(
                      ({ pageNumber }) => pageNumber === option.id,
                    );

                    if (pageToGo) {
                      navigateWithinForum(pageToGo.url);
                    }
                  }}
                />
              </div>
            )}
          </section>

          <PostPageSidebarStats
            viewCount={post.viewCount}
            totalComments={post.totalComments}
            totalReplies={post.totalReplies}
            postType={post.postType}
            isViewsCountEnabled={isViewsCountEnabled}
            postId={post._id}
          />
          {isSimilarPostsEnabled && (
            <PostPageSidebarNewPosts
              categoryId={post.categoryId}
              posts={posts}
              showRelatedPosts
              getIsPostNew={getIsPostNew}
            />
          )}
          {!isForumTabsEnabled && hasMultipleCategories && (
            <PostPageSidebarCategoryList categories={categories} allCategories={allCategories} />
          )}
        </div>
      </StickyContainer>
    );
  }
}

PostPageSidebar.propTypes = {
  t: PropTypes.func,
  unsubscribe: PropTypes.func,
  subscribe: PropTypes.func,
  post: PropTypes.object,
  commentCount: PropTypes.number.isRequired,
  viewCount: PropTypes.number.isRequired,
  isViewsCountEnabled: PropTypes.bool,
  isSimilarPostsEnabled: PropTypes.bool,
  isForumTabsEnabled: PropTypes.bool,
  isWixCommentsEnabled: PropTypes.bool,
  getIsPostNew: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const routeParams = getRouteParams(state);
  const posts = getRelatedAndNewPosts(state);
  const pagerStateItems = getPagerStateItems(state, ownProps.post._id);

  return {
    posts,
    paginationState: parsePagerStateItemsUrl(pagerStateItems, routeParams.categorySlug),
    subscribe: (...args) => {
      actions.setBiLocation(SIDEBAR, SUBSCRIBE_SUCCESS);
      actions.subscribeToPost(...args);
    },
    unsubscribe: (...args) => {
      actions.setBiLocation(SIDEBAR, UNSUBSCRIBE_SUCCESS);
      actions.unsubscribeFromPost(...args);
    },
    categories: getCategories(state),
    allCategories: getAllCategories(state),
    isViewsCountEnabled: getIsViewsCountEnabled(state, host.style),
    isSimilarPostsEnabled: getIsSimilarPostsEnabled(state, host.style),
    navigateWithinForum: actions.navigateWithinForum,
    getIsPostNew: getIsPostNew(state),
  };
};

export default flowRight(
  withTranslate,
  withFontClassName,
  withExperiment({
    isForumTabsEnabled: EXPERIMENT_FORUM_TABS,
    isWixCommentsEnabled: EXPERIMENT_WIX_COMMENTS,
  }),
  connect(mapRuntimeToProps, [REDUCERS.CATEGORIES, REDUCERS.RELATED_POSTS, REDUCERS.FOOTER_POSTS]),
)(PostPageSidebar);
