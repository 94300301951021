import React from 'react';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import PostLink from '../link/post-link';
import withTranslate from '../../../common/components/with-translate/with-translate';
import withDividerColor from '../../hoc/with-divider-color';
import styles from './post-page-sidebar-new-posts.scss';
import { SIDEBAR } from '../../constants/bi-locations';
import NewContentIndicator from '../new-content-indicator';
import withCardBorderWidth from '../../hoc/with-card-border-width';

const PostPageSidebarNewPosts = React.memo(
  ({ posts, showRelatedPosts, dividerColor, borderWidth, t, getIsPostNew }) => {
    return posts.length === 0 ? null : (
      <section
        className={classNames(styles.container, 'forum-text-color')}
        style={{ borderColor: dividerColor, borderWidth }}
        data-hook="sidebar-posts"
      >
        <span className={styles.title}>
          {t(
            showRelatedPosts
              ? 'post-page-sidebar-new-posts.related-posts'
              : 'post-page-sidebar-new-posts.title',
          )}
        </span>
        {posts.map(post => (
          <PostLink
            className={classNames(styles.post, 'forum-text-color', 'forum-link-hover-color')}
            post={post}
            biLocation={SIDEBAR}
            data-hook="post-title"
          >
            {post.title}
            <NewContentIndicator
              hideWord
              hideNumber
              hideBackground
              inline
              count={getIsPostNew(post._id) && 1}
            />
          </PostLink>
        ))}
      </section>
    );
  },
);

PostPageSidebarNewPosts.propsTypes = {
  posts: PropTypes.array,
  borderWidth: PropTypes.number.isRequired,
  getIsPostNew: PropTypes.func,
};

export default flowRight(
  withTranslate,
  withDividerColor,
  withCardBorderWidth,
)(PostPageSidebarNewPosts);
