import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withTranslate from '../../../common/components/with-translate/with-translate';
import styles from './mark-all-as-read-button.scss';
import { connect } from '../../../common/components/runtime-context';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_RECENT_ACTIVITY_CATS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { CheckMarkIcon } from '../icons/check-mark-icon';
import Button from '../button/button';

class MarkAllAsReadButton extends Component {
  markPostsAsRead = () => this.props.markPostsAsRead(this.props.category._id);
  render() {
    const { className, t } = this.props;

    if (!this.props.isRecentActivityCatsEnabled) {
      return null;
    }
    return (
      <Button
        isSecondary
        onClick={this.markPostsAsRead}
        isSmall
        data-hook="quick-best-answer"
        className={className}
      >
        <CheckMarkIcon className={classNames(styles.buttonIcon, 'button-fill')} />
        {t('recent-activity.mark-all-as-read')}
      </Button>
    );
  }
}

MarkAllAsReadButton.propTypes = {
  t: PropTypes.func,
  className: PropTypes.string,
  markPostsAsRead: PropTypes.func,
  category: PropTypes.object,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  markPostsAsRead: actions.markPostsAsRead,
});

export default flowRight(
  withTranslate,
  connect(mapRuntimeToProps),
  withExperiment({
    isRecentActivityCatsEnabled: EXPERIMENT_RECENT_ACTIVITY_CATS,
  }),
)(MarkAllAsReadButton);
